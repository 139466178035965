import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.scss"

const Work = () => (
  <Layout headline="Here is my workspace">
    <SEO siteTitle="Work" />
    <article>
    <p>Work and portfolio site is under construction</p>
    <Link to="/">back home</Link>
    </article>
  </Layout>
)

export default Work